@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';
@use '@sats-group/ui-lib/tokens/light';

.context-menu-v2 {
  $breakpoint: 600px;
  @include elevation.level(6);

  background: light.$surface-primary-default;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: corner-radius.$s corner-radius.$s 0 0;
  min-width: 300px;
  width: 100%;
  z-index: 100;
  padding: spacing.$s spacing.$xs spacing.$xl;
  animation: slide 0.3s ease-in;

  @media (min-width: $breakpoint) {
    position: absolute;
    left: initial;
    transform: none;
    border-radius: corner-radius.$s;
    padding: spacing.$s spacing.$xs;
    animation: none;
    bottom: auto;
    width: calc(100% - #{spacing.$m * 2});

    &--top {
      bottom: calc(100% + #{spacing.$xxs});
      left: 50%;
      transform: translateX(-50%);
    }

    &--top-right {
      bottom: calc(100% + #{spacing.$xxs});
      right: 0;
    }

    &--right {
      left: calc(100% + #{spacing.$xxs});
      top: 50%;
      transform: translateY(-50%);
    }

    &--bottom-right {
      right: 0;
      top: calc(100%);
    }

    &--bottom {
      left: 50%;
      top: calc(100% + #{spacing.$xxs});
      transform: translateX(-50%);
    }

    &--bottom-left {
      left: 0;
      top: calc(100% + #{spacing.$xxs});
    }

    &--left {
      right: calc(100% + #{spacing.$xxs});
      top: 50%;
      transform: translateY(-50%);
    }

    &--top-left {
      left: 0;
      bottom: calc(100% + #{spacing.$xxs});
    }
  }

  &__background-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: light.$fixed-background-secondary-default;
    opacity: 0.2;
    z-index: 99;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__texts-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$xs;
    border-bottom: 1px solid light.$ge-divider-default;

    &--center {
      align-items: center;
    }
  }

  &__texts {
    color: light.$on-surface-primary-default;
  }

  &__texts-description {
    color: light.$on-surface-primary-alternate;
  }

  &__list-items, &__list-items-bottom {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-items-bottom {
    border-top: 1px solid light.$ge-divider-default;
  }
}

@keyframes slide {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}
