@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.boot-camp-session {
  display: flex;
  flex-direction: column;
  gap: spacing.$xxs;
  background: light.$surface-primary-default;
  border-radius: corner-radius.$s;
  padding: spacing.$s;
  border-left: 10px solid light.$ge-signal-success;

  &--open {
    border-left: 10px solid light.$ge-signal-error;
  }

  &--edited {
    border-left: 10px solid light.$ge-signal-neutral;
  }

  &__title {
    color: light.$on-surface-primary-alternate;
  }

  &__time {
    display: flex;
    align-items: center;
    gap: spacing.$s;
    color: light.$on-surface-primary-default;
  }

  &__time-item {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$m;
  }

  &__input {
    flex: 1;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__edit-button {
    align-self: flex-end;
  }
}
