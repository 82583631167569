@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';

.popup-modal {
  $breakpoint: 600px;
  @include elevation.level(6);

  border-radius: corner-radius.$s;
  background: light.$surface-primary-default;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: calc(100% - #{spacing.$m * 2});
  z-index: 100;
  padding: spacing.$l;

  @media(min-width: $breakpoint) {
    position: absolute;
    left: initial;
    top: 50%;
    right: spacing.$xs;
    transform: none;
    width: 400px;
  }

  &--small {
    width: 300px;
    padding: spacing.$m spacing.$l;
  }

  &__background-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    z-index: 99;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }
 }
