@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing.$s;
  padding: spacing.$xl;

  &__content {
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing.$l;
    margin-bottom: spacing.$m;
    border-radius: 50%;

    &--blue {
      background-color: light.$signal-surface-information;
      color: light.$on-signal-surface-information-alternate;
    }

    &--gray {
      background: light.$signal-surface-neutral;
      color: light.$on-signal-surface-neutral-alternate;
    }
  }

  &__info {
    color: light.$on-background-primary-alternate;
  }
}
