@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/elevation';

.action-bar {
  $breakpoint: 500px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: light.$surface-primary-default;
  padding: spacing.$m 0;
  z-index: 4;
  @include elevation.level(2);

  @media (max-width: $breakpoint) {
    padding-bottom: spacing.$xl;
  }

  &__text {
    color: light.$on-surface-primary-alternate;
    margin-bottom: spacing.$s;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$s;

    @media (max-width: $breakpoint) {
      > * {
        flex: 1;
      }
    }
  }
}
