@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/light';

.context-menu-v2-item {
  &__list-item-link {
    text-decoration: none;
  }

  &__list-item-form {
    display: flex;
  }

  &__list-item-button, &__list-item-form-button  {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    width: 100%;
  }

  &__list-icon {
    margin-right: spacing.$s;
    display: flex;
    align-items: center;
    color: light.$on-surface-primary-disabled;

    &--theme-destructive {
      color: light.$on-buttons-on-destructive-outlined-default;

      &:hover {
        color: light.$on-buttons-on-destructive-outlined-hover;
      }
    }
  }

  &__list-item-link,
  &__list-item-button,
  &__list-item-form-button,
  &__list-item-form {
    cursor: pointer;
    color: light.$on-surface-primary-default;
    border-radius: corner-radius.$s;
    text-align: left;

    &:hover {
      background-color: light.$surface-primary-hover;
    }

    &--theme-destructive {
      color: light.$on-buttons-on-destructive-outlined-default;

      &:hover {
        background-color: light.$buttons-destructive-outlined-hover;
        color: light.$on-buttons-on-destructive-outlined-hover;
      }
    }
  }

  &__list-item-link-inner,
  &__list-item-button-inner,
  &__list-item-form-button-inner {
    display: flex;
    align-items: center;
  }

  &__list-item-link,
  &__list-item-button,
  &__list-item-form {
    padding: spacing.$s spacing.$xs;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: spacing.$xxs;
  }
}
