@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.boot-camp-list {
  $breakpoint: 900px;
  $content-width: 1128px;

  &__status {
    display: flex;
    @media (max-width: $breakpoint) {
      grid-area: status;
      justify-self: end;
    }
  }

  &__title {
    @media (max-width: $breakpoint) {
      grid-area: title;
    }
  }

  &__trainer {
    @media (max-width: $breakpoint) {
      grid-area: trainer;
    }
  }

  &__signups {
    @media (max-width: $breakpoint) {
      grid-area: signups;
    }
  }

  &__session {
    @media (max-width: $breakpoint) {
      grid-area: session;
    }
  }

  &__visibility {
    @media (max-width: $breakpoint) {
      grid-area: visibility;
      justify-self: end;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 2fr 50px;
    align-items: center;
    width: 100%;
    text-decoration: none;
    padding: spacing.$xs;

    &--trainer {
      grid-template-columns: 2fr 250px 2fr 1fr 2fr 50px;
    }

    &:hover {
      background-color: light.$surface-primary-hover;
      border-radius: corner-radius.$s;
    }

    @media (max-width: $breakpoint) {
      &:hover {
        border-radius: 0;
      }

      padding: spacing.$m;
      grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
      grid-template-areas:
        'title title title visibility status status'
        '. . . . . .'
        '. . . . . .'
        'signups session session session . .';
      grid-template-rows: auto auto spacing.$s auto;

      &--trainer {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
        grid-template-areas:
          'title title title visibility status status'
          'trainer trainer trainer trainer trainer trainer'
          '. . . . . .'
          'signups session session session . .';
      }
    }
  }

  &__description {
    color: light.$on-surface-primary-alternate;
    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__edit {
    position: absolute;
    bottom: spacing.$m;
    right: spacing.$m;
    min-height: 45px; // NOTE: To avoid the height changing because of the content

    @media (min-width: $breakpoint) {
      position: static;
      bottom: auto;
      top: auto;
    }
  }

  &__row {
    position: relative;
    max-width: $content-width;
    margin: auto;
    display: flex;
    align-items: center;
    color: light.$on-surface-primary-default;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: spacing.$m;
      width: calc(100% - spacing.$m);
      border-bottom: 1px solid light.$ge-divider-default;
    }

    @media (min-width: $breakpoint) {
      padding: spacing.$xxs 0;

      &::after {
        display: none;
      }
      border-bottom: 1px solid light.$ge-divider-default;
    }
  }

  &__headers {
    display: none;
    @media (min-width: $breakpoint) {
      max-width: $content-width;
      padding-left: spacing.$m;
      padding-right: spacing.$m;
      margin: auto;
      position: relative;
      display: grid;
      width: 100%;
      grid-template-columns: 2fr 2fr 1fr 2fr 50px;
      padding: spacing.$m spacing.$xs;
      color: light.$on-background-secondary-alternate;

      &--5-lines {
        grid-template-columns: 2fr 2fr 1fr 2fr 50px;
      }

      &--6-lines {
        grid-template-columns: 2fr 250px 2fr 1fr 2fr 50px;

        &.boot-camp-list__headers--can-edit {
          grid-template-columns: 2fr 2fr 1fr 2fr 50px 43px;
        }
      }

      &--7-lines {
        grid-template-columns: 2fr 250px 2fr 1fr 2fr 50px 43px;
      }
    }
  }

  &__header-lines {
    border-bottom: 1px solid light.$ge-divider-default;
    @media (min-width: $breakpoint) {
      border-top: 1px solid light.$ge-divider-default;
    }
  }
}
