@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.participants-list {
  $breakpoint: 800px;
  border-radius: corner-radius.$m;
  background-color: light.$background-secondary-default;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacing.$xs spacing.$m;
  }

  &__header-left {
    display: flex;
    gap: spacing.$xs;
  }

  &__list, &__list-hidden {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list-hidden {
    display: none;

    @media(min-width: $breakpoint) {
      display: block;
    }

    &--show {
      display: block;
    }
  }

  &__show-more-button-wrapper {
    border-top: 1px solid light.$ge-divider-default;
    padding: spacing.$xs 0;
    margin-left: spacing.$s;

    @media(min-width: $breakpoint) {
      display: none;
    }
  }

  &__show-more-button {
    margin-left: spacing.$xxs;
  }

  &__no-participants {
    display: flex;
    gap: spacing.$s;
    padding: spacing.$s spacing.$m;
  }

  &__no-participants-text {
    color: light.$on-surface-primary-alternate;
    margin-bottom: spacing.$s;
    margin-top: spacing.$xxs;
  }
}
