@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.progression-stepper {
  $breakpoint: 500px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__next {
    color: light.$on-background-primary-alternate;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: spacing.$xxs;
    margin: spacing.$s 0;

    @media (min-width: $breakpoint) {
      gap: spacing.$xxs;
    }

    &--small-spacing {
      @media (min-width: $breakpoint) {
        gap: 0;
      }
    }
  }
}
