@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.single-session {
  display: flex;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: spacing.$s;
  }

  &__border {
    width: 8px;
    border-radius: 0 corner-radius.$s corner-radius.$s 0;

    &--bookable {
      background: repeating-linear-gradient(
        -45deg,
        light.$ge-indicator-tags-neutral-default,
        light.$ge-indicator-tags-neutral-default 4px,
        light.$signal-surface-neutral 4px,
        light.$signal-surface-neutral 8px
      );
    }

    &--pt-pay {
      background: transparent;
      background-color: light.$ge-workouts-pt;
    }

    &--bootcamp {
      background: transparent;
      background-color: light.$ge-workouts-bootcamp;
    }

    &--pt-start {
      background: transparent;
      background-color: light.$ge-workouts-other;
    }

    &--other {
      background: transparent;
      border: 1px solid light.$ge-workouts-gym-floor;
    }

    &--gx {
      background: transparent;
      background-color: light.$ge-workouts-gx;
    }
  }

  &--bookable {
    background-color: light.$signal-surface-neutral;
    color: light.$on-signal-surface-neutral-alternate;
  }

  &--pt-pay {
    background-color: light.$workout-surface-pt;
    color: light.$on-workout-surface-pt-alternate;
  }

  &--bootcamp {
    background-color: light.$workout-surface-bootcamp;
    color: light.$on-workout-surface-bootcamp-alternate;
  }

  &--pt-start {
    background-color: light.$workout-surface-other;
    color: light.$on-workout-surface-other-alternate;
  }

  &--other {
    background-color: light.$workout-surface-gymfloor;
    color: light.$on-workout-surface-gymfloor-alternate;
  }

  &--gx {
    background-color: light.$workout-surface-gx;
    color: light.$on-workout-surface-gx-alternate;
  }

  &__top {
    display: flex;
    gap: spacing.$xs;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__time {
    color: light.$on-surface-primary-alternate;
  }
}
