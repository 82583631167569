@use '@sats-group/ui-lib/tokens/spacing';

.member-filters {
  @media (min-width: 1100px) {
    display: flex;
    align-items: flex-start;
    column-gap: spacing.$xl;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: spacing.$m;
  }

  &__header-button-desktop {
    display: none;
    @media (min-width: 500px) {
      display: flex;
    }
  }

  &__header-button-mobile {
    @media (min-width: 500px) {
      display: none;
    }
  }

  &__filter {
    &:last-child {
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: spacing.$l;
    }
  }

  &__search {
    display: flex;
    width: 100%;
  }

  &__selected {
    margin-top: spacing.$l;
  }
}
