@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.participant {
  $breakpoint: 500px;
  border-top: 1px solid light.$ge-divider-default;
  padding: spacing.$s spacing.$s spacing.$s 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  &--left-margin {
    margin-left: spacing.$s;
  }

  &__id {
    color: light.$on-background-primary-alternate;
  }

  &__dot {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__contact-info {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
    flex-wrap: wrap;

    @media (max-width: $breakpoint) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: spacing.$xs;
    }
  }
}
