@media (min-width: 1100px) {
  .member-filters {
    display: flex;
    align-items: flex-start;
    column-gap: 32px;
  }
}
.member-filters__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.member-filters__header-button-desktop {
  display: none;
}
@media (min-width: 500px) {
  .member-filters__header-button-desktop {
    display: flex;
  }
}
@media (min-width: 500px) {
  .member-filters__header-button-mobile {
    display: none;
  }
}
.member-filters__filter:last-child {
  width: 100%;
}
.member-filters__filter:not(:last-child) {
  margin-bottom: 24px;
}
.member-filters__search {
  display: flex;
  width: 100%;
}
.member-filters__selected {
  margin-top: 24px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9tZW1iZXItZmlsdGVycyIsInNvdXJjZXMiOlsibWVtYmVyLWZpbHRlcnMuc2NzcyIsIi4uLy4uLy4uL25vZGVfbW9kdWxlcy9Ac2F0cy1ncm91cC91aS1saWIvdG9rZW5zL3NwYWNpbmcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQURGO0lBRUk7SUFDQTtJQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsZUNWQTs7QURhRjtFQUNFOztBQUNBO0VBRkY7SUFHSTs7O0FBS0Y7RUFERjtJQUVJOzs7QUFLRjtFQUNFOztBQUVGO0VBQ0UsZUM5QkY7O0FEa0NGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFLFlDeENBIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nJztcblxuLm1lbWJlci1maWx0ZXJzIHtcbiAgQG1lZGlhIChtaW4td2lkdGg6IDExMDBweCkge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGZsZXgtc3RhcnQ7XG4gICAgY29sdW1uLWdhcDogc3BhY2luZy4keGw7XG4gIH1cblxuICAmX19oZWFkZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC13cmFwOiB3cmFwO1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIG1hcmdpbi1ib3R0b206IHNwYWNpbmcuJG07XG4gIH1cblxuICAmX19oZWFkZXItYnV0dG9uLWRlc2t0b3Age1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgQG1lZGlhIChtaW4td2lkdGg6IDUwMHB4KSB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgIH1cbiAgfVxuXG4gICZfX2hlYWRlci1idXR0b24tbW9iaWxlIHtcbiAgICBAbWVkaWEgKG1pbi13aWR0aDogNTAwcHgpIHtcbiAgICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgfVxuICB9XG5cbiAgJl9fZmlsdGVyIHtcbiAgICAmOmxhc3QtY2hpbGQge1xuICAgICAgd2lkdGg6IDEwMCU7XG4gICAgfVxuICAgICY6bm90KDpsYXN0LWNoaWxkKSB7XG4gICAgICBtYXJnaW4tYm90dG9tOiBzcGFjaW5nLiRsO1xuICAgIH1cbiAgfVxuXG4gICZfX3NlYXJjaCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICB3aWR0aDogMTAwJTtcbiAgfVxuXG4gICZfX3NlbGVjdGVkIHtcbiAgICBtYXJnaW4tdG9wOiBzcGFjaW5nLiRsO1xuICB9XG59XG4iLCIvLyBodHRwczovL3d3dy5maWdtYS5jb20vZmlsZS9XektDd1JZMDl6bjRyelJWZlkwWXZkUnQvc2F0cy1kcy1zdHlsZXM/bm9kZS1pZD00MDglM0E4NVxuJHh4czogNHB4O1xuJHhzOiA4cHg7XG4kczogMTJweDtcbiRtOiAxNnB4O1xuJGw6IDI0cHg7XG4keGw6IDMycHg7XG4keHhsOiA2NHB4O1xuJHh4eGw6IDEyOHB4O1xuXG4kaGVybzogMjU2cHg7XG4kbWluaW11bS12aWV3cG9ydC13aWR0aDogMzIwcHg7XG4iXX0= */